import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fce98862 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _75874527 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _90728760 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _cc70409a = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _0d3c87cc = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _4b461478 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _d727cc5a = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _06e8f6e0 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _d2f9bbec = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _7bfe3b24 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _4b354722 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _5f5ce12c = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _49200136 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _07e57ca0 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _788ee54b = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _3e971595 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _7d5ff066 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _fce98862,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _75874527,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _90728760,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _cc70409a,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _0d3c87cc,
    name: "receipt"
  }, {
    path: "/search",
    component: _4b461478,
    name: "search"
  }, {
    path: "/search-booking",
    component: _d727cc5a,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _06e8f6e0,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _d2f9bbec,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _7bfe3b24,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _4b354722,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _5f5ce12c,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _49200136,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _07e57ca0,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _788ee54b,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _3e971595,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _7d5ff066,
    name: "index"
  }, {
    path: "/dedicated",
    component: _7d5ff066,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _4b461478,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _788ee54b,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _5f5ce12c,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _0d3c87cc,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
